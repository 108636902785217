import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isEnabled}}\n  {{#let (unique-id) as |titleId|}}\n    <Dialog\n      @open={{this.showQuestAlert}}\n      class=\"pointer-events-auto aria-hidden:pointer-events-none aria-hidden:opacity-0\"\n      role=\"alertdialog\"\n      aria-labelledby={{titleId}}\n      aria-hidden={{if this.showQuestAlert \"false\" \"true\"}}\n      data-test-quest-notification\n    >\n      {{#if this.showQuestAlert}}\n        <Dialog::Overlay\n          class=\"flex items-center justify-center\"\n          role=\"document\"\n          tabindex=\"0\"\n          {{content-interactive\n            this.loader\n            externalController=this\n            manifests=this.jesterManifests\n            runtimeVariables=this.variables\n            onError=this.onContentError\n          }}\n        />\n      {{/if}}\n    </Dialog>\n  {{/let}}\n{{/if}}", {"contents":"{{#if this.isEnabled}}\n  {{#let (unique-id) as |titleId|}}\n    <Dialog\n      @open={{this.showQuestAlert}}\n      class=\"pointer-events-auto aria-hidden:pointer-events-none aria-hidden:opacity-0\"\n      role=\"alertdialog\"\n      aria-labelledby={{titleId}}\n      aria-hidden={{if this.showQuestAlert \"false\" \"true\"}}\n      data-test-quest-notification\n    >\n      {{#if this.showQuestAlert}}\n        <Dialog::Overlay\n          class=\"flex items-center justify-center\"\n          role=\"document\"\n          tabindex=\"0\"\n          {{content-interactive\n            this.loader\n            externalController=this\n            manifests=this.jesterManifests\n            runtimeVariables=this.variables\n            onError=this.onContentError\n          }}\n        />\n      {{/if}}\n    </Dialog>\n  {{/let}}\n{{/if}}","moduleName":"fast-phonics-client/components/quest/notification.hbs","parseOptions":{"srcName":"fast-phonics-client/components/quest/notification.hbs"}});
import { action } from '@ember/object'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import type QuestService from 'fast-phonics-client/services/quest'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type Owner from '@ember/owner'
import type { FeatureService } from '@blakeelearning/features'
import type SessionService from 'fast-phonics-client/services/session'
import type JesterLoaderService from 'fast-phonics-client/services/jester-loader'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'

interface QuestNotificationComponentArgs {
  alertAnytime?: boolean
}
export default class QuestNotificationComponent extends Component<QuestNotificationComponentArgs> {
  @service declare quest: QuestService

  @service declare session: SessionService

  @service declare errorHandler: ErrorHandlerService

  @service declare features: FeatureService

  @service declare jesterLoader: JesterLoaderService

  @service declare loadingUi: LoadingUiService

  variables

  constructor(owner: Owner, args: QuestNotificationComponentArgs) {
    super(owner, args)
    this.variables = {
      currencyAmount: this.session.student.rewardTotals.coins,
      name: this.session.student.firstName,
      quest: this.quest.currentQuest,
    }
    if (this.args.alertAnytime) this.quest.displayQuestAlertIfRequired()
  }

  get loader() {
    return this.jesterLoader.loader
  }

  get showQuestAlert() {
    return this.quest.showQuestAlert
  }

  get questGoalForNotification() {
    return this.quest.questGoalForNotification
  }

  get jesterManifests() {
    return [['index', 'three-essentials/quest-reward']]
  }

  get isEnabled() {
    const client = this.features.getClient()
    return client.getBooleanValue('quests', false)
  }

  @action
  acknowledgeQuestGoalAlert() {
    if (!this.questGoalForNotification) return

    void this.quest.acknowledgeQuestGoalAlert({
      goalId: this.questGoalForNotification.id,
    })
  }

  @action
  awarded() {
    this.acknowledgeQuestGoalAlert()
  }

  @action
  close() {
    this.quest.closeQuestAlert()
  }

  @action
  onContentError(error: Error) {
    this.errorHandler.handleContentUnhandledError(error)
  }

  @action
  readyForUserInput() {
    this.loadingUi.finish()
  }
}
