import Service, { service } from '@ember/service'
import { graphql } from 'fast-phonics-client/graphql'
import {
  QuestGoalStatus,
  type StudentQuestGoalAcknowledgeCompleteInput,
} from 'fast-phonics-client/graphql/graphql'
import { useQuery } from 'fast-phonics-client/resources/query'
import type ActivityService from './activity'
import type { Log } from '@blakeelearning/log'
import type { RouteModel } from '@ember/routing/router-service'
import { tracked } from '@glimmer/tracking'
import type RouterService from '@ember/routing/router-service'
import { useMutation } from 'fast-phonics-client/resources/mutation'
import type SessionService from './session'
import type LoadingUiService from './loading-ui'

graphql(/* GraphQL */ `
  fragment StudentQuestDataFragment on Student {
    id
    quest {
      primaryGoal {
        __typename
        goalType: __typename
        ... on QuestGoalPeak {
          rewardAmount
          id
          peak
          progressCurrent
          progressTotal
          status
        }
      }
    }
  }
`)

const StudentQuestQueryDocument = graphql(/* GraphQL */ `
  query GetStudentQuest {
    student {
      ...StudentQuestDataFragment
    }
  }
`)

export const acknowledgeQuestGoalAlertMutationDocument = graphql(/* GraphQL */ `
  mutation StudentQuestGoalAcknowledgeComplete(
    $input: StudentQuestGoalAcknowledgeCompleteInput!
  ) {
    studentQuestGoalAcknowledgeComplete(input: $input) {
      student {
        ...StudentQuestDataFragment
      }
    }
  }
`)
export default class QuestService extends Service {
  @service declare activity: ActivityService

  @service declare log: Log

  @service declare router: RouterService

  @service declare session: SessionService

  @service declare loadingUi: LoadingUiService

  @tracked showQuestAlert = false

  nextRoute?: [string] | [string, RouteModel] | undefined

  _studentQuestQuery = useQuery(this, () => {
    return {
      query: StudentQuestQueryDocument,
    }
  })

  _acknowledgeQuestGoalAlertMutation = useMutation(
    this,
    acknowledgeQuestGoalAlertMutationDocument,
  )

  get currentQuest() {
    return this._studentQuestQuery.current.data?.student.quest
  }

  get recommendedTargetRoute() {
    if (!this.currentQuest) return

    const primaryGoal = this.currentQuest.primaryGoal
    if (primaryGoal.status === QuestGoalStatus.Incomplete) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (primaryGoal.__typename === 'QuestGoalPeak') {
        const peak = primaryGoal.peak
        const positionInPeak = primaryGoal.progressCurrent + 1
        const result = this.activity.findStoneIdFor(peak, positionInPeak)

        if (result.ok) {
          if (result.id === 'index') {
            // These are chest unlocks
            return ['peaks.peak.stones', peak]
          }
          return ['stone', peak, result.id]
        } else {
          console.log(result.errorMessage)
          this.log.error(result.errorMessage)
        }
      }
    }
    return
  }

  get questGoalForNotification() {
    const primaryGoal = this.currentQuest?.primaryGoal
    if (primaryGoal?.status === QuestGoalStatus.ShowCompleteAlert) {
      return primaryGoal
    }
    return undefined
  }

  async acknowledgeQuestGoalAlert(
    input: StudentQuestGoalAcknowledgeCompleteInput,
  ) {
    await this._acknowledgeQuestGoalAlertMutation.current.mutate({
      variables: {
        input,
      },
    })
    await this.session.reloadStudent()
  }

  closeQuestAlert() {
    this.showQuestAlert = false
    if (this.nextRoute) {
      const nextRoute = this.nextRoute
      this.nextRoute = undefined
      // @ts-expect-error router params type no bueno
      this.router.transitionTo(...nextRoute)
    }
  }

  displayQuestAlertIfRequired(nextRoute?: [string] | [string, RouteModel]) {
    if (this.questGoalForNotification) {
      this.nextRoute = nextRoute
      this.loadingUi.start()
      this.showQuestAlert = true
    } else {
      // @ts-expect-error router params type no bueno
      if (nextRoute) this.router.transitionTo(...nextRoute)
    }
  }

  async fetch() {
    await this._studentQuestQuery.current.refetch()
    await this.setup()
  }

  async setup() {
    await this._studentQuestQuery.current.result()
  }
}

declare module '@ember/service' {
  interface Registry {
    quest: QuestService
  }
}
