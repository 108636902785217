import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import type ProgressService from 'fast-phonics-client/services/progress'
import type QuestService from 'fast-phonics-client/services/quest'

export default class RecommendedRoute extends Route {
  @service declare router: RouterService

  @service declare progress: ProgressService

  @service declare quest: QuestService

  override beforeModel() {
    // It does some unexpected things like skipping the intro video, and 
    // it also doesn’t work great with certain assumptions made about browser history in the app.
    // We can put the redirection back once we resolve these issues.
    // if (this.progress.showPlacementTest) {
    //   this.router.replaceWith('placement-test')
    //   return
    // }
    // if (this.quest.recommendedTargetRoute) {
    //   // @ts-expect-error router params type no bueno
    //   this.router.replaceWith(...this.quest.recommendedTargetRoute)
    //   return
    // }
    this.router.replaceWith('/')
  }
}
